import React, { useState } from 'react'
import { Checkbox } from 'antd'
import FacetCheckboxStyled from './FacetCheckboxStyled'

const FacetCheckbox = ({
  inputKey,
  form,
  forceUpdate,
  label
}) => {
  const [isChecked, setIsChecked] = useState(form.getFieldValue(inputKey) || false)

  function onCheckChange(checked) {
    form.setFieldsValue({ [`${inputKey}`]: checked })

    forceUpdate()

    setIsChecked(checked)
  }

  return (
    <FacetCheckboxStyled>
      <Checkbox 
        checked={isChecked}
        onChange={e => onCheckChange(e.target.checked)}
      >
        {label}
      </Checkbox>
    </FacetCheckboxStyled>
  )
}

export default FacetCheckbox
