import { get as localDbGet, set as localDbSet } from "idb-keyval";
import { DataStore } from "@reams/elias-store";
import AmplifyConfig from "../aws-exports";
import { Asset } from "models";
import { makeImageLocalKey } from "helpers/imageHelpers";
import md5 from "md5";
import { v4 as uuidv4 } from "uuid";
import { deviceImages } from "./deviceImages";
import { Auth } from "aws-amplify";

export async function fetchLocalImages({ item, facilityId, strip }) {
  let newImages = []
  for (let i = 0; i < item.images?.length; i++) {
    const newImage = { ...item.images[i] }
    let dataUri

    // if image uploaded
    if (item.images[i].picture) {
      dataUri = await localDbGet(
        makeImageLocalKey({
          hash: md5(item.images[i].picture.key),
          facilityId: facilityId,
        })
      )
    }

    // if image not uploaded
    if (!dataUri) {
      dataUri = await localDbGet(
        makeImageLocalKey({
          hash: item.images[i].hash,
          facilityId: facilityId,
        })
      );
    }

    // if image not uploaded & stored as pending
    if (!dataUri) {
      dataUri = await localDbGet(
        makeImageLocalKey({
          type: "pending",
          hash: item.images[i].hash,
          facilityId: facilityId,
        })
      )
    }

    newImage.dataUri = dataUri

    if (strip) {
      newImage.hash = md5(`${uuidv4()}${new Date().toTimeString()}`)
    }

    newImages.push(newImage)
  }

  return newImages
}

export async function setLocalImagesToState({
  item,
  facilityId,
  forceUpdate,
  setAreImagesLoaded,
  setImages,
  mounted,
  strip
}) {
  const images = await fetchLocalImages({ item, facilityId, strip })
  if (!mounted) return

  setImages(images)
  setAreImagesLoaded(true)
  forceUpdate()
}

async function setImageToLocalStorage(image, facility, tenantId) {

  const imageKeyPending = makeImageLocalKey({
    type: "pending",
    hash: image.hash,
    facilityId: facility.id,
  });

  const existingPendingImage = await localDbGet(imageKeyPending);
  if (existingPendingImage) {
    return;
  }

  // add to download to ensure download not triggered
  await deviceImages.addDownloaded({
    facilityId: facility.id,
    localKey: makeImageLocalKey({
      hash: md5(image.picture.key),
      facilityId: facility.id,
    }),
  });

  // set as a pending image to upload
  await localDbSet(imageKeyPending, image.dataUri);

  // set in mutation v2 queue to upload image
  let fileName = image.picture.key.split("/")[1];
  await localDbSet(
    `mutationv2-${fileName}`,
    JSON.stringify({
      facilityId: facility.id,
      hash: image.hash,
      key: image.picture.key,
      tenantId: tenantId,
    })
  );
}

export async function getImagesForAPI({
  facility,
  images: imagesFromForm,
  itemId,
  tenantData = {},
}) {
  const MAX_RANDOM_NUMBER_GENERATED = 1000000;
  const DEFAULT_ENVIRONMENT = "live5";

  const env = tenantData?.env || DEFAULT_ENVIRONMENT;
  const tenantId = tenantData?.tenantId || window.tenantId;
  const type = "jpeg";

  const imagesForAPI = imagesFromForm.map((image) => {
    const { dataUri, picture, ...fieldsForAPI } = image;
    if (picture?.key) {
      //if image exists already do not generate new file name and key
      return { ...fieldsForAPI, picture };
    }
    const fileName = `${itemId}_${Date.now()}${Math.floor(
      Math.random() * MAX_RANDOM_NUMBER_GENERATED
    )}`;

    const key = `${itemId}/${fileName}.${type}`;
    localDbSet(
      makeImageLocalKey({
        hash: md5(key),
        facilityId: facility.id,
      }),
      image.dataUri
    );

    const imageBuilt = {
      ...fieldsForAPI,
      picture: {
        bucket: `${env}-tenant-${tenantId}`,
        key,
        region: AmplifyConfig.aws_user_files_s3_bucket_region,
      },
    };


    setImageToLocalStorage({ ...imageBuilt, dataUri }, facility, tenantId)

    return imageBuilt;
  });

  return imagesForAPI;
}

export const changeQuantity = async (qty, id) => {
  if (qty <= 0 || typeof qty !== "number" || isNaN(qty)) {
    return;
  }

  try {
    const user = await Auth.currentAuthenticatedUser();
    if (!user || !Object.keys(user).length) {
      return;
    }

    const original = await DataStore.query(Asset, id);

    const updated = Asset.copyOf(original, (updated) => {
      let facets = Object.assign({}, original.facets);
      facets.quantity = qty;
      updated.facets = JSON.stringify(facets);
      updated.updatedUser = {
        givenName: user.attributes.given_name,
        familyName: user.attributes.family_name,
        email: user.attributes.email,
        date: new Date().toISOString()
      }
    })

    await DataStore.save(updated);
  } catch (error) {
    console.error("Error changing quantity: ", error);
  }
};
