import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { InputNumber, Button } from 'antd';
import { useDebounce } from 'use-debounce';
import './QuantityIncreaser.scss';

const QuantityIncreaser = (props) => {
  const MINIMUM = 1;

  const { inputValue, onChange } = props;

  const [hasBeenModified, setHasBeenModified] = useState(false);
  const [previousQuantity, setPreviousQuantity] = useState(inputValue || MINIMUM);
  const [quantity, setQuantity] = useState(inputValue || MINIMUM);
  const [debouncedQuantity] = useDebounce(quantity, 750);

  // Resets and updates the UI to reflect live data changes set outside of the Card View
  useEffect(() => {
    setHasBeenModified(false);
    setPreviousQuantity(inputValue || MINIMUM);
    setQuantity(inputValue || MINIMUM);
  }, [inputValue])

  // Call the onChange handler whenever debouncedQuantity changes
  useEffect(() => {
    if (hasBeenModified && Number.parseInt(debouncedQuantity) && debouncedQuantity !== previousQuantity) {
      onChange(debouncedQuantity);

      setPreviousQuantity(debouncedQuantity);
    }
  }, [hasBeenModified, previousQuantity, debouncedQuantity]);

  return (
    <div className={cx('button-pair', 'input-input')}>
      <Button
        className="input-decreaser"
        disabled={quantity === MINIMUM}
        onClick={() => {
          setHasBeenModified(true);
          
          setQuantity((qty) => Math.max(qty - 1, MINIMUM));
        }}
      >
        <span>-</span>
      </Button>
      <InputNumber
        className="quantity-input"
        defaultValue={MINIMUM}
        value={Number.parseInt(quantity)}
        min={MINIMUM}
        onClick={e => e.stopPropagation()}
        onChange={(value) => {
          setHasBeenModified(true);

          setQuantity(Number.parseInt(value));
        }}
        onBlur={e => {
          const fieldValue = Number.parseInt(e.target.value) || undefined;

          if (!fieldValue || fieldValue < MINIMUM) {
            setQuantity(previousQuantity);

            return;
          }

          fieldValue !== quantity && setQuantity(parseInt(fieldValue));
        }}
        autoComplete="off"
      />
      <Button
        className="input-increaser"
        disabled={!MINIMUM}
        onClick={() => {
          setHasBeenModified(true);

          setQuantity((qty) => qty + 1);
        }}
      >
        <span>+</span>
      </Button>
    </div>
  );
};

export default QuantityIncreaser;
