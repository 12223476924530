import { Select } from "antd";
import { Building, MapPin, World } from "components/Icons";
import { orderFacilitiesByLocation } from "helpers/hierarchyHelpers";
import { pruneChildren } from "helpers/parentChildHelpers";
import _ from "lodash";
import { generateIconTitle } from "./itemFilterHelpers";

export const FILTERSPLITCHAR = "_";

export const getKeyLocation = (item, filterKey, facets) => {
  const facet = facets.find((f) => f.key === filterKey);

  if (filterKey === "Asset Class") {
    return item.assetType.virtual ? "Virtual" : "Core";
  }

  if (!facet) return;

  if (facet.element === "facets") {
    return item.facets[filterKey];
  }

  return item[filterKey];
};

export const getKeyFromFilter = (filter) => {
  if (!filter.includes(FILTERSPLITCHAR)) {
    return filter;
  }
  return filter.substring(0, filter.indexOf(FILTERSPLITCHAR));
};

export const getValueFromFilter = (filter) => {
  return filter.substring(filter.indexOf(FILTERSPLITCHAR) + 1);
};

export const uniqueFilteredKeys = (filters) => {
  const fs = [];

  filters.forEach((filter) => {
    const { id } = filter;

    fs.push(getKeyFromFilter(id));
  });

  return _.uniq(fs);
};

export const treeObject = (
  title,
  value,
  children = [],
  selectable = false
) => ({
  title: title,
  selectable: selectable,
  value: value,
  key: value,
  children: children.length > 0 ? children : undefined,
});

export const buildRegionFilterableTree = ({ facilities = [], regions = {}, sites = [] }) => {
  const structuredFacilities = orderFacilitiesByLocation(facilities)
  
  return treeObject(
    "Location",
    "location",
    regions?.ids
      .sort((a, b) => regions[a]?.name < regions[b]?.name ? -1 : 1)
      .map((region) => {
        const siteObj = sites.reduce((acc, val) => {
          acc[val.id] = val
          return acc
        }, {});
        const siteTree = Object.keys(structuredFacilities[region] || {})
          .sort((a, b) => siteObj[a]?.name < siteObj[b]?.name ? -1 : 1)
          .map((sId) => {
            const siteFacilities = structuredFacilities[region][sId];

            const facilitiesTree = Object.values(siteFacilities)
            .sort((a, b) => a?.name < b?.name ? -1 : 1)
            .map((facility) => {
              return treeObject(
                generateIconTitle({
                  title: facility?.name, 
                  type: "facility", 
                  classes: "filterIcon",
                  Component: Building
                }), 
                `Facility${FILTERSPLITCHAR}${facility?.id}`, 
                _, 
                true);
            });

            return treeObject(
              generateIconTitle({
                title: siteObj[sId]?.name, 
                type: "site", 
                classes: "filterIcon",
                Component: MapPin
              }), 
              `Site${FILTERSPLITCHAR}${siteObj[sId]?.id}`, 
              facilitiesTree,
              true
            );
        })

        const regionTree = treeObject(
          generateIconTitle({
            title: regions[region]?.name, 
            type: "region", 
            classes: "filterIcon",
            Component: World
          }),
          `Region${FILTERSPLITCHAR}${regions[region]?.id}`,
          siteTree,
          true
        );

        return regionTree;
    }),
    false
  );
};

export const buildLocationFilterableTree = (
  assets,
  facilities,
  floors,
  spaces,
) => {
  const facilitiesAndFloors = Object.entries(assets?.assetTree || {})

  const tree = facilitiesAndFloors.map(([facilityId, floorsAndSpaces]) => {
    return {
      type: "facility",
      title: facilities[facilityId]?.name,
      value: `Facility${FILTERSPLITCHAR}${facilityId}`,
      key: `Facility${FILTERSPLITCHAR}${facilityId}`,
      selectable: true,
      children: Object.entries(floorsAndSpaces)
        .map(([floorId, spacesAndAssetIds]) => 
          floorId === "nothing" ? 
            null : 
            buildFloorFilterTree(floors, spaces, floorId, spacesAndAssetIds))
        .filter(x => x),
    }
  })

  return tree
};

function buildFloorFilterTree(floors, spaces, floorId, spacesAndAssetIds) {
  return {
    type: "floor",
    title: floors.items[floorId]?.name,
    value: `Floor${FILTERSPLITCHAR}${floorId}`,
    key: `Floor${FILTERSPLITCHAR}${floorId}`,
    selectable: true,
    facilityId: floors.items[floorId]?.facilityId,
    children: Object.keys(spacesAndAssetIds)
      .map((spaceId) => {
        return spaceId === "nothing" ? null : buildSpaceFilterTree(spaces, spaceId)
      })
      .filter(x => x),
  }
}

function buildSpaceFilterTree(spaces, spaceId) {
  return {
    type: "space",
    title: spaces.items[spaceId]?.name,
    value: `Space${FILTERSPLITCHAR}${spaceId}`,
    key: `Space${FILTERSPLITCHAR}${spaceId}`,
    selectable: true,
    floorId: spaces.items[spaceId]?.floorId,
  }
}

export function buildFilterTreeFromHierarchy(globalHierarchy, items) {
  let tree = JSON.parse(JSON.stringify(globalHierarchy));

  for (let i = 0; i < tree.length; i++) {
    convertHierarchyToFilter(tree[i], items);
  }

  tree = pruneChildren(tree);

  return tree;
}

function convertHierarchyToFilter(parent, items, depth = 0) {
  const value = `System${FILTERSPLITCHAR}${parent.tree.join(";")}`
  parent.title = parent.type;
  parent.value = value;
  parent.key = value;
  parent.selectable = true;

  const foundItems = items.find(
    (item) => item.assetType?.tree[depth] === parent.tree[depth]
  );

  if (foundItems) {
    parent.found = true;
    if (parent.children) {
      for (let i = 0; i < parent.children.length; i++) {
        convertHierarchyToFilter(parent.children[i], items, depth + 1);
      }
    }

    return
  }

  parent.found = false;
  parent.children = undefined;
}

export const buildFilterableTreeByFacet = (
  items,
  facet,
  onChange,
  filters = [],
  onFilterClose
) => {
  if (facet.type === "Numeric") {
    return filterableRatingNumeric(items, facet);
  }

  if (facet.filterable.type === "AutoBox") {
    return treeObject(facet.label, facet.key, [
      filterableAutoBox(items, facet, onChange, filters, onFilterClose),
    ]);
  }

  if (Array?.isArray(facet.options)) {
    return treeObject(
      facet.label,
      facet.key,
      filterableOptions(items, facet),
      false
    );
  }
};

export const TreeMultipleSelect = ({
  list,
  onChange,
  facetKey,
  value,
  onFilterClose,
}) => (
  <Select
    mode="multiple"
    style={{ width: "100%" }}
    placeholder="Select"
    autoFocus
    getPopupContainer={(trigger) => trigger.parentNode}
    value={value
      .map((v) => v.id)
      .filter((v) =>
        list.map((l) => `${facetKey}${FILTERSPLITCHAR}${l}`).includes(v)
      )}
    onChange={(c) => {
      onChange(c);
    }}
    onDeselect={(x) => {
      onFilterClose(x);
    }}
  >
    {list
      .sort((a, b) => (a < b ? -1 : 1))
      .map((l) => (
        <Select.Option key={`${facetKey}${FILTERSPLITCHAR}${l}`}>
          {l}
        </Select.Option>
      ))}
  </Select>
);

export const filterableAutoBox = (
  items,
  facet,
  onChange,
  filters,
  onFilterClose
) => {
  const list = _.uniq(
    items.map((a) => getKeyLocation(a, facet.key, [facet]))
  ).filter((x) => x);

  return {
    title: (
      <TreeMultipleSelect
        list={list}
        onChange={onChange}
        facetKey={facet.key}
        value={filters}
        onFilterClose={onFilterClose}
      />
    ),
    selectable: false,
  };
};

export const filterableRatingNumeric = (items, facet) => {
  const list = _.uniq(
    items.map((a) => getKeyLocation(a, facet.key, [facet]))
  ).filter((x) => x);

  if (list.length === 0) {
    return undefined;
  }

  return treeObject(facet.label, facet.key, [], true);
};

export const filterableOptions = (items, facet) => {
  const x = [];
  const options = _.uniq(
    items.map((a) => getKeyLocation(a, facet.key, [facet]))
  ).filter((x) => x);
  options
    .sort((a, b) => (a < b ? -1 : 1))
    .forEach((opt) => {
      x.push({
        title: facet?.options?.find((o) => o.code === opt)?.label || opt,
        value: `${facet.key}${FILTERSPLITCHAR}${opt}`,
        key: `${facet.key}${FILTERSPLITCHAR}${opt}`,
        selectable: true,
      });
    });

  return x;
};
