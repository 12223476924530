import { DataStore } from '@reams/elias-store'
import { Site } from "models"


const handleIncomingSites = (incomingSites = [], setSites) => {
  const siteObj = {}

  incomingSites.forEach(site => {
    siteObj[site.id] = site
  })

  setSites(siteObj)
}

export const getDataStoreSites = ({ setSites, tenantId, allocatedFacilities = {} }) => (
  DataStore.observeQuery(Site, (x) => {
    const { sites = [], regions = [] } = allocatedFacilities;
    
    return x.tenantId("eq", tenantId).or((x) => {
      let condition = x;
      for (let i = 0; i <= regions.length; i++) {
        const regionId = regions[i];
        condition = condition.regionId("eq", regionId);
      }
      for (let i = 0; i <= sites.length; i++) {
        const id = sites[i];
        condition = condition.id("eq", id);
      }
      return condition;
    })
  })
    .subscribe(async ({ items }) => {
      if (items) {
        handleIncomingSites(items, setSites)
      }
    })
)