import { SORT_ORDERS } from "constants";
export const VERSION_NUMBER = process.env.REACT_APP_BUILD_ID;
const BRANCH = process.env.REACT_APP_BRANCH
export const APP_ENV = ['master', 'qa'].includes(BRANCH) ? 'master' : BRANCH || 'devone'

export const DEFAULT_NUMBER_OF_ITEMS_IN_VIEW = 10;
export const INCREASE_NUMBER_OF_ITEMS_IN_VIEW_BY = 10;
export const APP_FILES_FOLDER_NAME = "app-files";
export const MAX_IMAGE_UPLOAD_WIDTH = 1250;
export const MAX_IMAGE_UPLOAD_HEIGHT = 1250;
export const IMAGE_UPLOAD_QUALITY = 1;
export const IMAGE_DOWNLOAD_CHUNK_SIZE = 3;
export const THROTTLE_INTERVAL_FETCH_ASSETS_MILLISECONDS = 5000;
export const INITIAL_SORT_ORDER_PER_COLUMN = SORT_ORDERS.DESCENDING;
export const AUTO_LOGOUT_TIMER_MILLISECONDS = 120 * 60 * 1000;
export const MAX_WIDTH_SIDEBAR_COLLAPSE_ON_TABLET = 915;
export const MAX_WIDTH_SIDEBAR_COLLAPSE_ON_DESKTOP = 850;
export const PING_INTERVAL_MILLISECONDS = 5000;
export const PING_TIMEOUT_MILLISECONDS = 1000;
export const LATENCY_PING_TIMEOUT_MILLISECONDS = 3000;
export const SUCCESSFUL_CONSECUTIVE_REQUESTS_REQUIRED = 3;
export const EOL_YEARS_LIMIT = 6;
export const CONDITION_A_LIMIT_YEARS = 2;
export const ELIAS_UK_HELPDESK_PHONE = "+44 333 090 5646";
export const ELIAS_USA_HELPDESK_PHONE = "+1 267 403 5566";
export const MAX_ACTIVE_FACILITIES = 2
export const MOBILE_LITE_URL = {
  prod: 'https://mobilelite.eliasam.com',
  master: 'https://mobilelite-ist1.dev2-aws.reams-elias.co.uk',
  demo: 'https://mobilelite.demo.eliasam.com',
  devone: 'https://mobilelite-dev1.dev2-aws.reams-elias.co.uk',
}
export const LOGIN_URL = {
  prod: 'https://login.eliasam.com',
  master: 'https://login-ist1.dev2-aws.reams-elias.co.uk',
  demo: 'https://login.demo.eliasam.com',
  devone: 'https://login-dev1.dev2-aws.reams-elias.co.uk'
};
export const MODULAR_WEB_APP_URL = {
  prod: 'https://www.eliasam.com',
  master: 'https://ist1.dev2-aws.reams-elias.co.uk',
  demo: 'https://analytics.demo.eliasam.com',
  devone: 'https://dev1.dev2-aws.reams-elias.co.uk'
};