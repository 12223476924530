import { DataStore } from '@reams/elias-store'
import { Region } from "models"


function handleIncomingRegions(items = [], setRegions) {
  const regionsIdArr = [];

  const regionsObj = items.reduce((acc, region) => {
    regionsIdArr.push(region.id);
    acc[region.id] = region;
    return acc;
  }, {});

  setRegions({ ...regionsObj, ids: regionsIdArr });
}

export const getDataStoreRegions = ({ setRegions, tenantId, allocatedFacilities = {} }) => (
  DataStore.observeQuery(Region, (x) => {
    const { regions = [] } = allocatedFacilities

    return x.tenantId("eq", tenantId).or((x) => {
      let condition = x;
      for (let i = 0; i <= regions.length; i++) {
        const id = regions[i];
        condition = condition.id("eq", id);
      }
      return condition;
    })
  })
    .subscribe(async ({ items }) => {
      if (items) {
        handleIncomingRegions(items, setRegions)
      }
    })
)