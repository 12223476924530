import styled from 'styled-components'

const FacetCheckboxStyled = styled.div`
  display: flex;
  align-items: center;
  min-height: 32px;
  margin-bottom: 4px;
`

export default FacetCheckboxStyled
