import { useState, useEffect, useMemo } from "react";
import SearchMenu from "./SearchMenu";
import { floorTypesDictionary } from "helpers/floorTypes";
import Icon from "@ant-design/icons";
import { iconChooser } from "./globalSearchHelpers";
import { useEntityData } from "EntityDataContext/EntityDataContext";

const searchFacilites = (facilities, searchText) =>
  facilities
    .filter(
      (f) =>
        f.name?.toLowerCase().match(searchText.toLowerCase()) ||
        f.externalId?.match(searchText)
    )
    .map((item) => ({ ...item, itemType: "facility" }));

const searchFloorType = (searchText, floorTypesDictionary) => {
  let res = [];
  for (let item in floorTypesDictionary) {
    if (
      item.toLowerCase().includes(searchText.toLowerCase()) ||
      floorTypesDictionary[item]
        .toLowerCase()
        .includes(searchText.toLowerCase())
    ) {
      res = [...res, item];
    }
  }
  return res;
};

const searchFloors = (floors, searchText, floorTypeRes) =>
  floors
    .filter(
      (f) =>
        floorTypeRes?.includes(f.floorType) ||
        f.name?.toLowerCase().match(searchText.toLowerCase())
    )
    .map((item) => ({ ...item, itemType: "floor" }));

const searchSpaces = (spaces, searchText) =>
  spaces
    .filter(
      (s) =>
        s.name?.toLowerCase().match(searchText.toLowerCase()) ||
        s.localName?.toLowerCase().match(searchText.toLowerCase())
    )
    .map((item) => ({ ...item, itemType: "space" }));

const searchAssets = (assets, searchText, includeExistingBarcode) => {
  const lowerSearchText = searchText.toLowerCase()

  const searchResults = []
  
  Object.values(assets.items)
    .forEach((asset) => {
      if (
        asset.name?.toLowerCase()
          .match(lowerSearchText) 
        || asset.id?.toLowerCase()
          .match(lowerSearchText) 
        || asset?.externalId?.toLowerCase()
          .match(lowerSearchText) 
        || asset.assetType?.description?.toLowerCase()
          .match(lowerSearchText) 
        || asset.notes?.description?.toLowerCase()
          .match(lowerSearchText) 
        || asset.facets?.manufacturer?.toLowerCase()
          .match(lowerSearchText) 
        || asset.facets?.barcode?.toLowerCase()
          .match(lowerSearchText) 
        || asset.facets?.model?.toLowerCase()
          .match(lowerSearchText) 
        || (includeExistingBarcode 
            && asset.facets?.["existing-asset-number"]
              ?.toLowerCase()
              .match(lowerSearchText))
        ) {
          searchResults.push({...asset, itemType: "asset"})
        }
    })

    return searchResults
  }

function buildSearchResults({
  facilities,
  floors,
  spaces,
  assets,
  searchText,
  floorTypesDictionary,
  includeExistingBarcode,
}) {
  let res = [];
  if (searchText?.length > 2) {
    const searchedFacilitiesRes = searchFacilites(facilities, searchText);

    const searchedFloorTypeRes = searchFloorType(
      searchText,
      floorTypesDictionary
    );

    const searchedFloorsRes = searchFloors(
      floors,
      searchText,
      searchedFloorTypeRes
    );

    const searchedSpacesRes = searchSpaces(spaces, searchText);


    const searchedAssetsRes = searchAssets(
      assets,
      searchText,
      includeExistingBarcode
    );

    res = [
      ...searchedFacilitiesRes,
      ...searchedFloorsRes,
      ...searchedSpacesRes,
      ...searchedAssetsRes,
    ];
  }
  return res;
}

const SearchEngine = ({
  data,
  setSearchText,
  setIncludeExistingBarcode,
  setDropdownOpen,
  resultsNumber,
  setResultsNumber,
  displaySearch
}) => {
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    setSearchResult(data);
  }, [data, resultsNumber]);

  return (
    <SearchMenu
      searchResults={searchResult}
      searchTextAPI={setSearchText}
      includeExistingBarcodeAPI={setIncludeExistingBarcode}
      displayMenu={setDropdownOpen}
      displaySearch={displaySearch}
      resultsNumber={resultsNumber}
      setResultsNumber={setResultsNumber}
    />
  );
};

const SearchAPI = ({
  dropdownOpen,
  setDropdownOpen,
}) => {
  const [includeExistingBarcode, setIncludeExistingBarcode] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [displaySearch, setDisplaySearch] = useState(false);
  const [resultsNumber, setResultsNumber] = useState(10);

  const { 
    assets,
    facilities,
    facilitiesToDownload,
    floors,
    spaces, 
  } = useEntityData()

  const resultsData = useMemo(
    () =>
      buildSearchResults({
        facilities: facilitiesToDownload
          .map(fId => facilities?.[fId])
          .filter(x => x),
        floors: Object.values(floors?.items || {}),
        spaces: Object.values(spaces?.items || {}),
        assets,
        searchText,
        floorTypesDictionary,
        includeExistingBarcode,
      }),
    [ searchText, 
      searchText.length > 2, 
      includeExistingBarcode,
      assets,
      facilities,
      floors,
      spaces 
    ]
  )

  useEffect(() => {
    if (!dropdownOpen && displaySearch) {
      setDisplaySearch(false);
    }
    if (!displaySearch) setResultsNumber(10);
  }, [dropdownOpen]);

  useEffect(() => {
    if (!displaySearch) setResultsNumber(10);
  }, [displaySearch]);

  return (
    <>
      <Icon
        style={{
          width: "1.5rem",
          height: "1.5rem",
          display: !displaySearch ? "inherit" : "none",
        }}
        component={iconChooser["search"]}
        onClick={() => {
          setDisplaySearch(true);
        }}
      />

      <div
        style={{
          display: displaySearch ? "flex" : "none",
          maxWidth: "550px",
          flexGrow: 1,
        }}
      >
        <SearchEngine
          data={resultsData}
          setSearchText={setSearchText}
          setIncludeExistingBarcode={setIncludeExistingBarcode}
          setDropdownOpen={setDropdownOpen}
          resultsNumber={resultsNumber}
          displaySearch={displaySearch}
          setResultsNumber={setResultsNumber}
        />
      </div>
    </>
  );
};

export default SearchAPI;
