import { useEffect, useRef, useState } from 'react'
import { Form, Radio } from 'antd'
import { displayFormLabel } from './formHelpers'
import { manufacturers } from './manufacturers'
import { BYPASS_OPTIONS, BYPASS_OPTIONS_BARCODE } from './drawerData'
import { formValidationRules } from './formValidationRules'
import cx from 'classnames'
import fetchFormControl from './form/formControl'
import { useEntityData } from 'EntityDataContext/EntityDataContext'
const conditionTags = require('../core/conditionTags.json')

function calculateBypassOptions(key) {
  if (key === 'facets_barcode') {

    return BYPASS_OPTIONS_BARCODE
  }

  return BYPASS_OPTIONS
}

export function searchAndPrependList(setList, list, val) {
  const o = list.filter((o) =>
    o.label.toLowerCase().includes(val.toLowerCase())
  )

  if (!o.find((ob) => ob.code === val && val !== '')) {
    o.unshift({ label: val, code: val })
  }

  o.unshift({ label: '', code: '' })

  setList(o)
}

export function noPrefixOptions(barcodePrefixes) {
  if (barcodePrefixes === undefined) {

    return true
  }

  return barcodePrefixes?.length < 1
}

export function DisplayFieldForFacet({
  form,
  itemDefinition,
  facetDefinition,
  forceUpdate,
  images,
  assetDefinition,
  item
}) {
  let validationRules = []
  let dependsOn = []
  let valuePropName = undefined

  let {
    type,
    key,
    options,
    label,
    validation,
    showFormLabel = true,
    step,
    default: defaultValue
  } = facetDefinition

  const hasLabel = showFormLabel
  const isMandatory = validation?.mandatory
  const originalKey = key
  const value = form.getFieldValue(originalKey)
  const byPassKey = `byPass_${originalKey}`

  const {tenantData, assets: {barcodes}} = useEntityData()

  const [focus, setFocus] = useState(false)
  const [ isDisabled, setIsDisabled ] = useState(
    (form.getFieldValue(byPassKey) !== undefined && originalKey !== 'facets_barcode') || facetDefinition.disabled || false
  )
  const [manufacturerOptions, setManufacturerOptions] = useState([])
  
  const barcodePrefixes = tenantData?.barcodePrefixes
  const arePrefixOptions = noPrefixOptions(barcodePrefixes)
  const assetIdCharLimit = tenantData?.barcodePrefixRegex
  
  const blurTimeout = useRef(null)

  useEffect(() => {
    if (type === 'AutoBox') {
      const value = form.getFieldValue(key)

      if (!value) {
        setManufacturerOptions(manufacturers)

        return
      }

      searchAndPrependList(setManufacturerOptions, manufacturers, value)
    }

    return () => {
      if (blurTimeout.current) {
        clearInterval(blurTimeout.current)
      }
    }
  }, [])

  if (!itemDefinition?.facets?.includes(facetDefinition?.facetId)) {
    return null
  }

  if (facetDefinition.element && facetDefinition.element.length > 0) {
    key = `${facetDefinition.element}_${key}`
  }

  function onBlurTimeout() {
    blurTimeout.current = setTimeout(() => {
      setFocus(false)
    }, 6000)
  }

  const formControl = fetchFormControl({
    type,
    item,
    key,
    options,
    step,
    forceUpdate,
    barcodePrefixes,
    form,
    facetDefinition,
    isMandatory,
    isDisabled,
    hasLabel,
    valuePropName,
    label,
    value,
    itemDefinition,
    manufacturerOptions,
    setManufacturerOptions,
    setFocus,
    onBlurTimeout,
    conditionTags
  })

  formValidationRules({
    RegExp,
    byPassKey,
    validation,
    form,
    item,
    images,
    assetDefinition,
    isMandatory,
    key,
    barcodes,
    label,
    facetDefinition,
    validationRules,
    dependsOn,
    assetIdCharLimit,
    tenantData
  })

  const displayTags = (key, item, arePrefixOptions) => {
    if (key === 'notes_condition') {

      return 'tags-and-condition-fields'
    }

    if (!item && !arePrefixOptions && key === 'facets_barcode') {

      return 'wide-group-fields'
    } else {
      return 'form-element'
    }
  }

  return (
    <div className={cx(displayTags(key, item, arePrefixOptions))} key={key}>
      {hasLabel && displayFormLabel({ label, isDisabled, isMandatory })}
      <Form.Item
        name={key}
        hasFeedback
        valuePropName={valuePropName}
        shouldUpdate={dependsOn.length === 0}
        dependencies={dependsOn}
        rules={validationRules}
        initialValue={defaultValue}
      >
        {formControl}
      </Form.Item>
      {((validation?.allowByPass && focus) ||
        form.getFieldValue(byPassKey)) && (
        <div className="bypass-options">
          <Form.Item name={byPassKey} shouldUpdate key={byPassKey}>
            <Radio.Group>
              {calculateBypassOptions(key).map((option, index) => {
                return (
                  <Radio
                    value={option}
                    onClick={() => {
                      const currentBypassValue = form.getFieldValue(byPassKey)
                      
                      if (option === currentBypassValue) {
                        form.setFieldsValue({ [byPassKey]: undefined})

                        if (originalKey !== 'barcode') {
                          setIsDisabled(false)
                        }
                      } else {
                        form.setFieldsValue({ [byPassKey]: option })

                        if (originalKey !== 'barcode') {
                          form.setFieldsValue({ [`facets_${originalKey}`]: undefined })

                          setIsDisabled(true)
                        }     
                      }

                      if (originalKey === 'barcode') {
                        {
                          // Need to focus the Barcode field to ensure deselected bypass is removed from form.
                          // Refs would not pass through, therefore targetting rendered html element.
                          document.querySelectorAll('input[dataid="barcode-input-field"]')[0].focus()
                        }
                      }

                      form.validateFields([ [`facets_${originalKey}`] ])

                      forceUpdate()
                    }}
                    key={index}
                  >
                    {option}
                  </Radio>
                )
              })}
            </Radio.Group>
          </Form.Item>
        </div>
      )}
    </div>
  )
}
