import { DataStore, syncExpression } from '@reams/elias-store';
import {
  set as localDbSet,
  del as localDbDelete,
  get as localDbGet
} from "idb-keyval";
import { Asset, Space, Floor, Facility, Site, Region } from "models";
import { makeImageLocalKey } from "helpers/imageHelpers";
import awsExports from "../aws-exports";
import md5 from "md5";
import ImageOutbox from "./imageOutbox";
import { API, Auth } from 'aws-amplify';
import { Hub } from '@aws-amplify/core'

function targetFacilities() {
  return window.targetFacilities ?? []
}

export async function configureDataStore() {
  DataStore.configure({
    ...awsExports,
    errorHandler: async (error) => {
      console.info("ELIAS DataStore error:", error);
      const { model, operation, process, message } = error;

      if (error.errorType === "ConditionalCheckFailedException") {
        console.error("Caught error ConditionalCheckFailedException, simulating an update", error);
        setTimeout(() => handleFailedCreate(error), 500);
      } else {
        const dsErrorType = `error_on_${process}:${operation}_${model}`;
        const newError = { [`${message}_@BREAK@_${new Date().toISOString()}`]: JSON.stringify(error) }

        const existingErrors = await localDbGet(dsErrorType) || {};

        const errorsRef = Object.keys(existingErrors) || [];
        const errorExists = errorsRef
          ?.some(key => key?.split('_@BREAK@_')[0] || null === message)

        if (errorExists) {
          console.info('skip insert, error exists')
        } else {
          console.info('insert new error', newError)
          localDbSet(dsErrorType, { ...existingErrors, ...newError });
        }
      }
    },
    maxRecordsToSync: 1000000,
    syncPageSize: 1000,
    fullSyncInterval: 60 * 14, // minutes
    pollOffline: { enabled: true, interval: 60000 },
    syncExpressions: [
      syncExpression(Region, async () => {
        return (region) => region.tenantId("eq", window.tenantId);
      }),
      syncExpression(Site, async () => {
        return (site) => site.tenantId("eq", window.tenantId);
      }),
      syncExpression(Facility, async () => {
        return (facility) => facility.tenantId("eq", window.tenantId);
      }),
      syncExpression(Asset, async () => {
        return (asset) => {
          return asset.tenantId("eq", window.tenantId).or((asset) => {
            let condition = asset;
            const facilities = targetFacilities();
            if (!facilities || !facilities.length) {
              condition = condition.facilityId("eq", "nothing");
              return condition;
            }

            for (let i = 0; i < facilities.length; i++) {
              const facilityId = facilities[i];
              condition = condition.facilityId("eq", facilityId);
            }
            return condition;
          });
        };
      }),
      syncExpression(Space, async () => {
        return (space) => {
          return space.tenantId("eq", window.tenantId).or((space) => {
            let condition = space;
            const facilities = targetFacilities();
            if (!facilities || !facilities.length) {
              condition = condition.facilityId("eq", "nothing");
              return condition;
            }

            for (let i = 0; i < facilities.length; i++) {
              const facilityId = facilities[i];
              condition = condition.facilityId("eq", facilityId);
            }

            return condition;
          });
        };
      }),
      syncExpression(Floor, async () => {
        return (floor) => {
          return floor.tenantId("eq", window.tenantId).or((floor) => {
            let condition = floor;
            const facilities = targetFacilities();
            if (!facilities || !facilities.length) {
              condition = condition.facilityId("eq", "nothing");
              return condition;
            }

            for (let i = 0; i < facilities.length; i++) {
              const facilityId = facilities[i];
              condition = condition.facilityId("eq", facilityId);
            }

            return condition;
          });
        };
      }),
    ],
  }, Auth, API, Hub);
}

async function handleFailedCreate(error) {
  let model;
  let { localModel } = error;
  if (localModel.spaceId) {
    model = Asset;
  } else if (localModel.floorId) {
    model = Space;
  } else if (localModel.facilityId) {
    model = Floor;
  } else if (localModel.siteId) {
    model = Facility;
  }
  const original = await DataStore.query(model, localModel.id);
  console.info("RETRY create record:", original);
  try {
    await DataStore.save(
      model.copyOf(original, (updated) => {
        updated.randomNumber = Math.floor(Math.random() * 1000000);
      })
    ).then((res) => {
      console.info("RETRY create success", res);
    })
  } catch (error) {
    console.error('Error retry create:', error.message);
  }
}

export async function onDataStoreEvent(hubData) {
  const { event, data } = hubData.payload;
  if (event === "networkStatus") {
    if (data.active) {
      new ImageOutbox().resume();
    }
  }
  if (event === "outboxMutationProcessed") {
    onMutationProcessed({
      item: data.element,
      modelName: data.model.name,
    });
  }
}

async function onMutationProcessed({ item, modelName }) {
  let facilityId = item.facilityId;

  if (modelName === "Facility") {
    facilityId = item.id;
  }
  if (item._deleted) {
    if (item.images) {
      setTimeout(async () => {
        for (let i = 0; i < item.images.length; i++) {
          const image = item.images[i];
          await localDbDelete(
            makeImageLocalKey({
              hash: md5(image.picture.key),
              facilityId,
            })
          );
        }
      }, 2000); // we want to do this a bit later to not interfere with checking for whether the images have been downloaded
      return;
    }
  }
  const outbox = new ImageOutbox();
  outbox.resume();
}
