import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd'
import moment from 'moment'
import LifecycleInterventionInputStyled from './LifecycleInterventionInputStyled'

const LifecycleInterventionInput = ({
  inputKey = 'lifecycle-intervention-year',
  form = {},
  forceUpdate = () => {}
}) => {
  const DEFAULT_LIFECYCLE = 0
  const MAX_INTERVENTION_DELTA = 30 // 30 years from EoL
  const DEFAULT_LABEL = 'Lifecycle Intervention (year)'
  const DATE_FORMAT = 'YYYY-MM-DD'

  const installDate = form.getFieldValue('facets_install-date') || undefined
  const currentLifecycleInterventionYear = form.getFieldValue(`facets_${inputKey}`) || undefined
  
  const [isDisabled, setIsDisabled] = useState(true)
  const [dateValue, setDateValue] = useState(undefined)
  const [defaultEoLYear, setDefaultEoLYear] = useState(undefined)
  const [label, setLabel] = useState(DEFAULT_LABEL)
  const [maxInterventionYear, setMaxInterventionYear] = useState(0)
  
  useEffect(() => {
    if (installDate) {
      const installedYear = new Date(installDate).getFullYear()
      const lifecycle = form.getFieldValue('assetType')?.lifecycle || DEFAULT_LIFECYCLE
      const defaultEoLYear = installedYear + lifecycle
  
      setDefaultEoLYear(moment(defaultEoLYear, DATE_FORMAT))
      setMaxInterventionYear(installedYear + lifecycle + MAX_INTERVENTION_DELTA)
  
      if (defaultEoLYear) {
        setLabel(`Lifecycle Intervention (default ${defaultEoLYear})`)
      }
  
      if (!currentLifecycleInterventionYear) {
        form.setFieldsValue({ [inputKey]: defaultEoLYear })
  
        setDateValue(moment(defaultEoLYear, DATE_FORMAT)) 
      }
    } else {
      setLabel(DEFAULT_LABEL)
      setDateValue(undefined)
    }

    if (currentLifecycleInterventionYear) {
      setDateValue(moment(currentLifecycleInterventionYear, DATE_FORMAT))
    }

    setIsDisabled(!Boolean(installDate))
  }, [ form, installDate, inputKey, currentLifecycleInterventionYear ])

  return (
    <LifecycleInterventionInputStyled>
      <label className="mandatory">{label}</label>
      <DatePicker
        disabled={isDisabled}
        disabledDate={(currentDate) => {
          const lowerDateRange = new Date()
          const upperDateRange = new Date(`01 January ${maxInterventionYear} 00:00:00 UTC`)

          return currentDate < lowerDateRange || currentDate > upperDateRange
        }}
        getPopupContainer={(trigger) => trigger.parentNode}
        picker="year"
        value={dateValue}
        onChange={(_date, dateString) => {
          console.log({dateString})
          if (dateString !== '') {
            form.setFieldsValue({ [inputKey]: dateString })

            setDateValue(moment(dateString, DATE_FORMAT))
          } else {
            form.setFieldsValue({ [inputKey]: defaultEoLYear?.toDate()?.getFullYear() })

            setDateValue(defaultEoLYear)
          }

          forceUpdate()
        }}
      />
    </LifecycleInterventionInputStyled>
  )
}

export default LifecycleInterventionInput
