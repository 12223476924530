import { Auth } from "aws-amplify";
import { useState } from "react";
import { keys as localDbKeys, set as localDbSet } from "idb-keyval";
import { Tooltip } from "antd";
import { isServerOnline } from "helpers/apiHelpers";
import { LOGIN_URL } from "config";
import { APP_ENV } from "config";

/**
 * Helper function to add leading zero to a number if it is less than 10.
 * @param {number} num - The number to format.
 * @returns {string} The formatted number.
 */
function twoDigits(num) {
  return ("0" + num).slice(-2);
}

/**
 * Formats a date and time string.
 * @param {string} a - The date and time string to format.
 * @param {boolean} [includeTime=false] - Whether to include the time in the formatted string.
 * @returns {string} The formatted date and time string.
 */
export function formatDateTime(a, includeTime = false) {
  const b = new Date(a);
  const date = `${twoDigits(b.getDate())}/${twoDigits(
    b.getMonth() + 1
  )}/${b.getFullYear()}`;
  const time = `${twoDigits(b.getHours())}:${twoDigits(b.getMinutes())}`;
  return `${date}${includeTime ? ` ${time}` : ``}`;
}

/**
 * Custom hook to force a component to re-render.
 * @returns {Function} The function to trigger a re-render.
 */
export function useForceUpdate() {
  const [value, setValue] = useState(0); //eslint-disable-line no-unused-vars
  return () => setValue((value) => ++value); // update the state to force render
}

/**
 * Converts a Blob object to a data URI.
 * @param {Blob} blob - The Blob object to convert.
 * @returns {Promise<string>} A Promise that resolves with the data URI.
 */
export async function getDataURIFromBlob(blob) {
  const dataUri = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        resolve(reader.result);
      },
      false
    );

    if (blob) {
      reader.readAsDataURL(blob);
    } else {
      reject();
    }
  });
  return dataUri;
}

/**
 * Converts a data URI to a Blob object.
 * @param {string} dataURI - The data URI to convert.
 * @returns {Blob} The Blob object.
 */
export function getBlobFromDataURI(dataURI) {
  var binary = atob(dataURI.split(",")[1]);
  var array = [];
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
}

/**
 * Retrieves the set of keys stored in the local database.
 * @returns {Promise<Set>} A Promise that resolves with the set of keys.
 */
export async function getLocalDbKeysSet() {
  const allLocalDbKeys = await localDbKeys();
  const keySet = new Set();
  for (let i = 0; i < allLocalDbKeys.length; i++) {
    keySet.add(allLocalDbKeys[i]);
  }
  return keySet;
}

/**
 * Builds a URL based on the provided facility, floor, and space.
 * @param {Object} options - The options for building the URL.
 * @param {Object} options.facility - The facility object.
 * @param {Object} options.floor - The floor object.
 * @param {Object} options.space - The space object.
 * @returns {string} The built URL.
 */
export function buildURL({ facility, floor, space }) {
  let url = "/";
  if (facility) {
    url = `/facility/${facility.id}/`;
  }
  if (floor) {
    url = `/floor/${floor.id}/`;
  }
  if (space) {
    url = `/space/${space.id}/`;
  }
  return url.replace(/\s+/g, "-");
}

/**
 * Logs out the user and redirects to the login page.
 * @param {boolean} isOnline - Whether the server is online.
 * @param {boolean} setReferer - Whether to set the referer in the redirect URL.
 */
export async function logOut(isOnline, setReferer) {
  let redirectURL = null;

  await localDbSet("facility-alert-closed", false);

  if (isOnline) {
    try {
      isOnline = await isServerOnline();
      redirectURL = `${LOGIN_URL[APP_ENV]}/?signout=true`;
      if (setReferer) {
        redirectURL += `&referer=inspections`;
      }
    } catch (e) {
      isOnline = false;
    }
  }

  if (!isOnline) {
    redirectURL = null;
  } else {
    await Auth.signOut();
  }

  if (redirectURL) {
    console.info('Routing to...', redirectURL)
    window.location.href = redirectURL;
  }
}

/**
 * Sets the user name based on the given family name and given name.
 * @param {Object} options - The options for setting the user name.
 * @param {string} options.familyName - The family name.
 * @param {string} options.givenName - The given name.
 * @returns {string} The formatted user name.
 */
export const setUserName = ({ familyName, givenName } = {}) =>
  familyName && givenName ? `- ${givenName.charAt(0)} ${familyName}` : "";

/**
 * Handles the user name based on the given item.
 * @param {Object} item - The item object.
 * @returns {string} The formatted user name.
 */
const handleUserName = (item) => {
  if (item.updatedUser) return setUserName(item.updatedUser);
  if (item.createdUser) return setUserName(item.createdUser);
  if (item.createdBy) return `- ${item.createdBy}`;
  return "";
};

/**
 * Helper functions for cleaning date fields in an object.
 */
export const cleanDate = {
  /**
   * Gets the created date from the user object.
   * @param {Object} user - The user object.
   * @returns {string|undefined} The created date or undefined if not found.
   */
  created: (user) => ['date', 'createdAt'].reduce((acc, fieldName) => acc !== undefined ? acc : user?.[fieldName], undefined),
  /**
   * Gets the updated date from the user object.
   * @param {Object} user - The user object.
   * @returns {string|undefined} The updated date or undefined if not found.
   */
  updated: (user) => ['date', 'updatedAt'].reduce((acc, fieldName) => acc !== undefined ? acc : user?.[fieldName], undefined)
}

/**
 * Gets the client or server date and time stamp. Client first, hence UI will show the local time.
 * @param {string} [localTime] - The local time stamp.
 * @param {string} [serverTime] - The server time stamp.
 * @returns {string} The formatted date and time stamp.
 */
const getClientOrServerDateTimeStamp = (localTime = undefined, serverTime = undefined) => {
  const dateTime = localTime ?? serverTime;

  if (!dateTime || !dateTime.length) return "-";

  return formatDateTime(dateTime, true);
}

/**
 * Gets the last updated date for an item. Prioritises the updated over the created date.
 * @param {Object} item - The item object.
 * @returns {string} The last updated date.
 */
function getLastUpdatedDate(item) {
  if ((item.updatedUser && cleanDate.updated(item.updatedUser))
    || item.updatedAt) {
    return getClientOrServerDateTimeStamp(cleanDate.updated(item.updatedUser), item.updatedAt);
  }

  if ((item.createdUser && cleanDate.created(item.createdUser))
    || item.createdAt) {
    return getClientOrServerDateTimeStamp(cleanDate.created(item.createdUser), item.createdAt);
  }
}

/**
 * Renders the last updated date and user name tooltip.
 * @param {Object} item - The item object.
 * @returns {JSX.Element} The rendered JSX element.
 */
export const renderUpdatedAt = (item) => (
  <>
    {getLastUpdatedDate(item)}
    {
      <Tooltip
        placement={"top"}
        title={
          (item.updatedUser &&
            `${item.updatedUser?.givenName} ${item.updatedUser?.familyName} ${item.updatedUser?.email}`) ||
          (item.createdUser &&
            `${item.createdUser?.givenName} ${item.createdUser?.familyName} ${item.createdUser?.email}`)
        }
      >
        &nbsp;
        {handleUserName(item)}
      </Tooltip>
    }
  </>
);
